<template>
  <div>
    <div class="form-group">
      <label for="">Medio de pago</label>
      <app-select-payment-method
        v-model="payment_method_id"
      ></app-select-payment-method>
    </div>
    <div class="form-group">
      <label for="">Monto</label>
      <app-input-money
        :moneyCode="money_code"
        :quantity="amount"
        :disabled="true"
      ></app-input-money>
    </div>
    <div class="form-group">
      <label for="">Fecha</label>
      <app-datepicker v-model="date"></app-datepicker>
    </div>

    <!-- <div class="form-group">
      <label for="">Descuento</label>
      <app-input-money
        :quantity.sync="discount"
        :moneyCode="money_code"
      ></app-input-money>
    </div>
    <div class="form-group">
      <label for="">Razon del descuento</label>
      <app-textarea v-model="reason_discount"></app-textarea>
    </div> -->

    <app-button-submit @click="save()" class="btn-success">
      Pagar
    </app-button-submit>
  </div>
</template>
<script>
// import { AdminService } from "../../admin-module";
// import { DentalClinicService } from "../service";
import { GeneralService } from "../../general-module/GeneralService";
import { myMoment, momentDateFormat } from "../../utils/myMoment";
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    discount: 0,
    reason_discount: "",
    payment_method_id: 1,
    amount: null,
    money_code: 1,
    date: myMoment().format(momentDateFormat),
    id: null
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    load(l) {
      this.id = l.id;
      this.amount = l.amount;
    },
    save() {
      GeneralService.saveLiquidation({
        lets_pay: 1,
        ...this.$data
      }).then(() => {
        this.$emit("sumitted");
      });
    }
  }
};
</script>

<style></style>
