<template>
  <div class="row">
    <div class="col">
      <h4>LIQUIDACIONES</h4>

      <app-ctrl-year-month
        @input="
          date = $event;
          $refs.tr.gl();
        "
        class="mb-2"
      ></app-ctrl-year-month>

      <app-table-registers
        :getList="getList"
        ref="tr"
        :exeGetChildAfterMount="false"
      >
        <template slot="table">
          <tr>
            <th>FUNCIONARIO</th>
            <th>MONTO</th>
            <th>ESTADO</th>
            <th>ACCIONES</th>
          </tr>
          <tr v-for="l in list.data" :key="l.id">
            <td>{{ l.employee_name }}</td>
            <td>
              <app-span-money
                :quantity="l.amount"
                :moneyCode="1"
              ></app-span-money>
            </td>
            <td>
              <app-badge-status
                :value="!!l.output_id"
                :title="l.paid_out_at"
                on="Pagado"
                off="Pendiente"
              ></app-badge-status>
            </td>
            <td>
              <div class="btn-group">
                <router-link
                  class="btn btn-sm btn-light"
                  :to="{
                    path: `/dental-clinic/liquidations/payments/${l.employee_id}?employee_liquidation_id=${l.id}`
                  }"
                >
                  <i class="fa fa-link"></i>
                </router-link>
                <router-link
                  class="btn btn-sm btn-success"
                  :to="`/dental-clinic/liquidations/${l.id}`"
                >
                  <i class="fas fa-file-excel"></i>
                </router-link>
              </div>
              <!-- <button
                v-show="!l.output_id"
                @click="
                  $refs.elModalLiquidar.show();
                  $refs.elLiquidar.load(l);
                "
                class="btn btn-sm btn-success"
              >
                <i class="fa fa-money"></i> Pagar
              </button> -->
            </td>
          </tr>
        </template>
      </app-table-registers>
    </div>

    <app-modal-basic ref="elModalLiquidar">
      <h4>PAGAR LIQUIDACION</h4>
      <liquidar ref="elLiquidar"></liquidar>
    </app-modal-basic>
  </div>
</template>
<script>
// import http from "../../utils/http";
// import navbar from "./Navbar";
import liquidar from "./Liquidar";
import { GeneralService } from "../../general-module/GeneralService";

export default {
  components: {
    // navbar,
    liquidar
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    list: {},
    date: {}
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    // this.getList();
  },

  methods: {
    getList(params) {
      return new Promise((rsv) => {
        GeneralService.getEmployeeLiquidations({
          ...params,
          ...this.date
        }).then((data) => {
          this.list = data;
          rsv(data);
        });
      });
    }
  }
};
</script>

<style></style>
